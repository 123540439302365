/* eslint-disable prettier/prettier */
import React from "react";
import {
  CContainer,
  CRow,
  CCol,
  CCard,
  CCardBody,
  CCardTitle,
  CCardText,
} from "@coreui/react";

const TermsCondition = () => {
  return (
    <>
      <CContainer className="mt-2 mb-2">
        <CRow>
          <CCol className="box-shadow">
            <CCard className="mt-3 mb-3">
              <CCardBody>
                <CCardTitle style={{ fontSize: '30px', color: '#e05902' }}>Privacy Policy</CCardTitle>
                <CCardText>
                  <p>
                    This Privacy Policy describes how PARISHRAM IT SOLUTIONS
                    PRIVATE LIMITED collects, uses, discloses, and safeguards
                    personal information when you use our mobile application
                    entitled “Ride2Destination”. By downloading, installing, or
                    using the App, you agree to the practices described in this
                    Privacy Policy.
                  </p>

                  <h4>1. Interpretation and Definitions</h4>
                  <p>
                    <strong>1.1. Interpretation</strong>
                    <br />
                    The words of which the initial letter is capitalized have
                    meanings defined under the following conditions. The
                    following definitions shall have the same meaning regardless
                    of whether they appear in singular or in plural.
                  </p>

                  <p>
                    <strong>1.2. Definitions</strong>
                    <br />
                    For the purposes of this Privacy Policy:
                    <ul>
                      <li>
                        <strong>Affiliate:</strong> means an entity that
                        controls, is controlled by or is under common control
                        with a party, where control means ownership of 50% or
                        more of the shares, equity interest or other securities
                        entitled to vote for election of directors or other
                        managing authority.
                      </li>
                      <li>
                        <strong>Application:</strong> refers to Ride to
                        Destination, the software program provided by the
                        Company.
                      </li>
                      <li>
                        <strong>Company:</strong>(referred to as either
                        &apos;the Company&apos;, &apos;We&apos;, Us&apos; or
                        &apos;Our&apos; in this Agreement) refers to PARISHRAM
                        IT SOLUTIONS PRIVATE LIMITED, H. No. 4765, PL. No. 85,
                        WD. No. 6, Mahajan wadi NR Pushkar HM, Hingna, Nagpur,
                        441110, Maharashtra. .
                      </li>
                      <li>
                        <strong>Country refers to:</strong> India.
                      </li>
                      <li>
                        <strong>Personal Data</strong> is any information that
                        relates to an identified or identifiable individual..
                      </li>
                      <li>
                        <strong>You</strong> means the individual accessing or
                        using the application on behalf of which such individual
                        is accessing or using the application, as applicable.
                      </li>
                    </ul>
                  </p>

                  <h4>2. Information We Collect</h4>
                  <p>
                    We gather the specified information below to deliver the
                    services you select, meet our legal responsibilities, and
                    uphold our commitments to third parties according to our
                    User Agreement. The term ‘Personal Information’ refers to
                    the data you share and we collect for the ensuing reasons:
                  </p>

                  <h5>2.1 Registration on the Application</h5>
                  <p>
                    Data that you supply during the application registration
                    process, which may encompass details about your personal
                    identity like your name, gender, age, etc., your contact
                    information such as your email address, mailing addresses,
                    phone (mobile or other types), and/or fax numbers. The data
                    might also comprise details like your banking information
                    (including credit/debit card) and any other data related to
                    your income and/or lifestyle; billing information, payment
                    history, etc. (as provided by you).
                  </p>

                  <h5>2.2 Other Information</h5>
                  <p>
                    We may also gather additional information and documents,
                    including but not limited to:
                    <ol>
                      <li>
                        Your transactional history (excluding banking details)
                        related to your e-commerce activities and purchasing
                        patterns.
                      </li>
                      <li>
                        Your usernames, passwords, email addresses, and other
                        security-related information that you use in connection
                        with our Services.
                      </li>
                      <li>
                        Data that you or a third party have created and that you
                        wish to store on our servers, such as image files,
                        documents, etc.
                      </li>
                      <li>
                        Data that is publicly available or received from any
                        third party, including social media channels. This
                        includes, but is not limited to, personal or
                        non-personal information from your linked social media
                        channels (like name, email address, friend list, profile
                        pictures, or any other information that is allowed to be
                        received as per your account settings) as part of your
                        account information.
                      </li>
                      <li>
                        Information about any other traveller(s) for whom you
                        make a booking through your registered redBus account.
                        In this case, you must confirm and represent that each
                        of the other traveller(s) for whom a booking has been
                        made has agreed to have the information you shared
                        disclosed to us and further shared by us with the
                        concerned service provider(s).
                      </li>
                    </ol>
                  </p>

                  <h5>2.3 Usage Information</h5>
                  <p>
                    The collected Personal Information may be utilized in the
                    following ways:
                  </p>
                  <h5>2.3.1 During a Booking</h5>
                  <p>
                    During the booking process, we might use Personal
                    Information that includes payment details such as the
                    cardholders name, credit/debit card number (in encrypted
                    form) with an expiration date, banking details, wallet
                    details, etc., as shared and permitted to be stored by you.
                    We may also use the list of travellers information available
                    in or linked with your account. This information is
                    presented to the User at the time of making a booking to
                    expedite your booking process.
                  </p>
                  <h5>2.3.2 Your Personal Information may also be used for various reasons including but not 
limited to:</h5>
                  <ol>
                    <li>To keep you updated on the transaction status.</li>
                    <li>
                      Your usernames, passwords, email addresses, and other
                      security-related information used by you in relation to
                      our Services.
                    </li>
                    <li>
                      To send booking confirmations via SMS, WhatsApp, or any
                      other messaging service.
                    </li>
                    <li>To send updates or changes to your booking(s).</li>
                    <li>
                      To allow our customer service to contact you, if
                      necessary.
                    </li>
                    <li>
                      To customize the content of our website, mobile site, and
                      mobile app.
                    </li>
                    <li>
                      To request reviews of products or services or any other
                      improvements.
                    </li>
                    <li>To send verification message(s) or email(s).</li>
                    <li>
                      To validate/authenticate your account and to prevent any
                      misuse or abuse.
                    </li>
                  </ol>
                  <h5>2.4 Feedback:</h5>
                  <p>
                    We appreciate the feedback and thoughts from our Users, both
                    digitally and in-person. Participation in these is
                    completely voluntary. Generally, the gathered information is
                    compiled and utilized to enhance our Application, other
                    Sales Channels, services, and to create attractive content,
                    features, and promotions for members based on Feedback
                    outcomes. The identity of the participants remains anonymous
                    unless specified otherwise stated in the Feedbac
                  </p>
                  <h6>USER-GENERATED CONTENT (UGC)</h6>
                  <p>
                    The company offers its users the opportunity to share their
                    experiences through reviews, ratings, and general poll
                    questions. Customers also have the option to pose questions
                    about a service provided by the Company or respond to
                    questions asked by other users. The Company may employ a
                    third party to reach out to you and collect feedback about
                    your recent booking with the Company. While participation in
                    the feedback process is entirely optional, you may still
                    receive emails, notifications (via SMS, Whatsapp, or any
                    other messaging service) inviting you to share your
                    feedback. The reviews can be in written or video format and
                    may also be displayed on other travel or travel-related
                    platforms.
                  </p>
                  <p>
                    The User-Generated Content (UGC) that the Company collects
                    may include:
                    <ol>
                      <li>Reviews and Ratings</li>
                      <li>Preferred Vehicle Choices</li>
                      <li>Crowd-Sourced Data Collection (poll questions)</li>
                    </ol>
                  </p>
                  <h5>2.5 HOW LONG DO WE KEEP YOUR PERSONAL INFORMATION:</h5>
                  <p>
                    The Company will store your Personal Information on its
                    servers for a duration that is reasonably necessary for the
                    objectives outlined in this policy. There may be situations
                    where we keep your Personal Information for extended
                    periods, for example, when we are obligated to do so in
                    compliance with any legal, regulatory, tax, or accounting
                    requirements.
                  </p>

                  <h4>3. Disclosure of Your Information</h4>
                  <p>
                    We may share your personal information with third parties,
                    including and in situations:
                  </p>
                  <ol>
                    <li>
                      <strong>Service Providers:</strong> We may engage
                      third-party service providers to assist us with various
                      services, such as hosting, data analysis, and customer
                      support.
                    </li>
                    <li>
                      <strong>For Business Transfers:</strong> We may share or
                      transfer Your personal information in connection with, or
                      during negotiations of, any merger, sale of Company
                      assets, financing, or acquisition of all or a portion of
                      Our business to another company.
                    </li>
                    <li>
                      <strong>With Affiliates:</strong> We may share Your
                      information with Our affiliates, in which case we will
                      require those affiliates to honour this Privacy Policy.
                      Affiliates include Our parent company and any other
                      subsidiaries, joint venture partners or other companies
                      that We control or that are under common control with Us.
                    </li>
                    <li>
                      <strong>With Business Partners:</strong> We may share Your
                      information with Our business partners to offer You
                      certain products, services or promotions.
                    </li>
                    <li>
                      <strong>With Your Consent:</strong> We may disclose Your
                      personal information for any other purpose with Your
                      consent.
                    </li>
                  </ol>
                  <h4>4. REQUIRED PERMISSIONS FOR USING OUR APPLICATIONS</h4>
                  <p>
                    When the Application is installed on your phone or tablet, a
                    list of necessary permissions appears for the app to
                    function optimally. The list cannot be customized. The
                    permissions that Application needs and the data that will be
                    accessed and its usage are as follows:
                  </p>
                  <h6>Android Permissions:</h6>
                  <ol>
                    <li>
                      <strong>Device and App history:</strong> We require your
                      device permission to obtain information about your device,
                      such as OS (operating system) name, OS version, mobile
                      network, hardware model, unique device identifier,
                      preferred language, etc. Based on these inputs, we aim to
                      optimize your travel booking experience.
                    </li>
                    <li>
                      <strong>Identity:</strong> This permission allows us to
                      know about the details of your account(s) on your mobile
                      device. We use this information to auto-fill your email
                      IDs and provide a typing-free experience. It also helps us
                      associate email IDs with a specific user to offer you
                      exclusive travel deals, wallet cash-backs, etc. It also
                      facilitates your Facebook and Google+ login.
                    </li>
                    <li>
                      <strong>Location:</strong> This permission allows us to
                      offer you location-specific deals and provide a
                      personalized experience. When you launch the redBus app to
                      make a travel booking, we autodetect your location so that
                      your nearest city is auto-filled. We also need this
                      permission to help you track your bus concerning your
                      location.
                    </li>
                    <li>
                      <strong>SMS:</strong> With your permission, we can read
                      your SMS to automatically fill in the ‘OTP’ during
                      transactions and verify your mobile number. This ensures a
                      smooth booking experience without needing to exit the app
                      to read and enter the OTP.
                    </li>
                    <li>
                      <strong>Phone:</strong>The app needs permission to make
                      phone calls, allowing you to directly call bus operators,
                      hotels, and our customer service centers from the app.
                    </li>
                    <li>
                      <strong>Contacts:</strong>Granting us access to your
                      contacts allows us to offer social features like ticket or
                      location sharing with friends. It also lets you choose
                      numbers from your contacts for mobile recharges in the
                      app.
                    </li>
                    <li>
                      <strong>Photo/Media/Files:</strong>Our app’s libraries use
                      these permissions to let users save and upload multimedia
                      reviews
                    </li>
                    <li>
                      <strong>Wi-Fi Connection Information:</strong>If you
                      permit us to detect your Wi-Fi connection, we can optimize
                      your bandwidth for multimedia uploads
                    </li>
                    <li>
                      <strong>Device ID and Call Information:</strong>This
                      permission helps us identify your Android ID to uniquely
                      identify users and access your contact details to pre-fill
                      certain fields for a seamless booking experience.
                    </li>
                    <li>
                      <strong>Camera:</strong>We use this permission to take
                      photos of the boarding point or bus before the journey,
                      which can be uploaded as part of multimedia reviews
                    </li>
                    <li>
                      <strong>Calendar:</strong>This permission lets us add your
                      travel plans to your calendar
                    </li>
                  </ol>

                  <h6>iOS Permissions:</h6>
                  <ol>
                    <li>
                      <strong>Notifications:</strong> Opting in for
                      notifications allows us to send exclusive deals,
                      promotional offers, travel updates, etc., to your device.
                      If you opt out, travel updates like booking confirmation,
                      refunds (in case of cancellation), etc., will be sent via
                      SMS.
                    </li>
                    <li>
                      <strong>Contacts:</strong> This permission lets us know
                      the details of your account(s) on your mobile device. We
                      use this information to auto-fill your email IDs for a
                      typing-free experience. It also helps us associate email
                      IDs with a user to provide exclusive travel offers, wallet
                      cash-backs, etc., and facilitates your Facebook and
                      Google+ login.
                    </li>
                    <li>
                      <strong>Location:</strong> This permission lets us offer
                      location-specific deals and a personalized experience.
                      When you launch the redBus app to book travel, we
                      auto-detect your location to auto-fill your nearest city.
                      We also need this permission to help you track your bus
                      relative to your location.
                    </li>
                    <li>
                      <strong>Photo/Media/Files:</strong> Our app’s libraries
                      use these permissions to let users save and upload
                      multimedia reviews.
                    </li>
                    <li>
                      <strong>Camera:</strong> We use this permission to take
                      photos of the boarding point or bus before the journey,
                      which can be uploaded as part of multimedia reviews.
                    </li>
                    <li>
                      <strong>Calendar:</strong> This permission lets us add
                      your travel plans to your calendar.
                    </li>
                  </ol>
                  <h4>5. Data Security</h4>
                  <p>
                    We take reasonable measures to protect your personal
                    information from unauthorized access, disclosure,
                    alteration, or destruction. However, please be aware that no
                    method of data transmission over the internet or electronic
                    storage is entirely secure, and we cannot guarantee absolute
                    security:
                  </p>
                  <h4>6. Changes to this Privacy Policy</h4>
                  <p>
                    We take reasonable measures to protect your personal
                    information from unauthorized access, disclosure,
                    alteration, or destruction. However, please be aware that no
                    method of data transmission over the internet or electronic
                    storage is entirely secure, and we cannot guarantee absolute
                    security:
                  </p>
                  <h4>7. Contact Information</h4>
                  <p>
                    If you have any questions or concerns about this Privacy
                    Policy or the data we collect, please contact us at{" "}
                    <a href="mailto:ride2destination2023@gmail.com">
                      ride2destination2023@gmail.com
                    </a>{" "}
                    or{" "}
                    <a href="mailto:support@ride2destination.in">
                      support@ride2destination.in
                    </a>
                    .
                  </p>
                </CCardText>
              </CCardBody>
            </CCard>
          </CCol>
        </CRow>
      </CContainer>
    </>
  );
};

export default TermsCondition;
